<template>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th>Player</th>
        <th>Played</th>
        <th>W</th>
        <th>L</th>
        <th>Win %</th>
      </tr>
    </thead>
    <tbody>
      <!-- <router-link :to="{ path: '/player/' + player.name }" v-for="player in standings" :key="player.name" tag="tr"> -->
      <tr data-testid="standings-row" v-for="player in standings" :key="player.name" @click="goToPlayer(player.name)">
        <td data-testid="standings-col-name">{{ player.name }}</td>
        <td data-testid="standings-col-played">{{ player.played }}</td>
        <td>{{ player.wins }}</td>
        <td>{{ player.losses }}</td>
        <td>{{ player.winPctg }}%</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { computed } from 'vue'
import router from '@/router'

export default {
  name: 'Standings',
  props: {
    matches: Array,
  },
  setup(props) {
    const goToPlayer = (name) => {
      router.push({ name: 'Player', params: { name } })
    }
    const standings = computed(() => buildStandings(props.matches))
    return { standings, goToPlayer }
  },
}

const buildStandings = (matches) => {
  const playersDict = {}
  matches.forEach((m) => {
    if (!playersDict[m.p1.name]) playersDict[m.p1.name] = { name: m.p1.name, wins: 0, losses: 0 }
    if (!playersDict[m.p2.name]) playersDict[m.p2.name] = { name: m.p2.name, wins: 0, losses: 0 }

    playersDict[m.p1.name].wins += m.p1.score
    playersDict[m.p1.name].losses += m.p2.score

    playersDict[m.p2.name].wins += m.p2.score
    playersDict[m.p2.name].losses += m.p1.score
  })

  const standings = Object.values(playersDict)
  standings.forEach((p) => {
    p.played = p.wins + p.losses
    p.winPctg = Math.ceil((p.wins / (p.wins + p.losses)) * 100)
  })

  standings.sort((p1, p2) => {
    if (p1.winPctg > p2.winPctg) return -1
    if (p1.winPctg < p2.winPctg) return 1
    return 0
  })

  return standings
}
</script>

<style scoped>
table {
  width: 90%;
  margin: 0 auto 30px auto;
}
tr:hover {
  cursor: pointer;
}
</style>
