import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })

const app = createApp(App)
app.use(router)
app.mount('#app')
