<template>
  <Pagination :data="matches" :perPage="perPage">
    <template v-slot="{ results }">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Player 1</th>
            <th>Player 2</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          <tr data-testid="match-row" v-for="match in results" :key="match.id">
            <td data-testid="p1-name" :class="match.p1.score > match.p2.score ? 'bold' : ''">{{ match.p1.name }} ({{ match.p1.score }})</td>
            <td data-testid="p2-name" :class="match.p2.score > match.p1.score ? 'bold' : ''">{{ match.p2.name }} ({{ match.p2.score }})</td>
            <td data-testid="date">{{ match.date }}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </Pagination>
</template>

<script>
import Pagination from './Pagination.vue'

export default {
  components: { Pagination },
  name: 'Stats',
  props: {
    matches: Array,
    perPage: Number,
  },
}
</script>

<style scoped>
table {
  width: 90%;
  margin: 0 auto 30px auto;
}

td.bold {
  font-weight: bold;
}
</style>
