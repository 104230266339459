<template>
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th>Player</th>
        <th>Played</th>
        <th>W</th>
        <th>L</th>
        <th>Win %</th>
      </tr>
    </thead>
    <tbody>
      <!-- <router-link :to="{ path: '/player/' + player.name }" v-for="player in standings" :key="player.name" tag="tr"> -->
      <tr v-for="player in opponentStats" :key="player.name" @click="goToPlayer(player.name)">
        <td>{{ player.name }}</td>
        <td>{{ player.played }}</td>
        <td>{{ player.wins }}</td>
        <td>{{ player.losses }}</td>
        <td>{{ player.winPctg }}%</td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td>TOTALS</td>
        <td>{{ totals.played }}</td>
        <td>{{ totals.wins }}</td>
        <td>{{ totals.losses }}</td>
        <td>{{ totals.winPctg }}%</td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { computed } from 'vue'
import router from '@/router'

export default {
  name: 'StatsVsOpponent',
  props: {
    matches: Array,
    playerName: String,
  },
  setup(props) {
    const opponentStats = computed(() => buildOpponentStats(props.matches, props.playerName))
    const totals = computed(() => buildTotals(opponentStats.value))
    const goToPlayer = (name) => router.push({ name: 'Player', params: { name } })

    return { opponentStats, totals, goToPlayer }
  },
}

const buildTotals = (opponentStats) => {
  return opponentStats.reduce(
    (acc, curr) => {
      acc.played += curr.played
      acc.wins += curr.wins
      acc.losses += curr.losses
      acc.winPctg = Math.ceil((acc.wins / (acc.wins + acc.losses)) * 100)
      return acc
    },
    {
      played: 0,
      wins: 0,
      losses: 0,
      winPctg: 0,
    },
  )
}

const buildOpponentStats = (matches, playerName) => {
  const dict = {}

  matches.forEach((m) => {
    if (m.p1.name === playerName) {
      if (!dict[m.p2.name]) dict[m.p2.name] = { name: m.p2.name, wins: 0, losses: 0 }
      dict[m.p2.name].wins += m.p1.score
      dict[m.p2.name].losses += m.p2.score
    } else if (m.p2.name === playerName) {
      if (!dict[m.p1.name]) dict[m.p1.name] = { name: m.p1.name, wins: 0, losses: 0 }
      dict[m.p1.name].wins += m.p2.score
      dict[m.p1.name].losses += m.p1.score
    }
  })

  const result = Object.values(dict)
  result.forEach((p) => {
    p.played = p.wins + p.losses
    p.winPctg = Math.ceil((p.wins / (p.wins + p.losses)) * 100)
  })

  result.sort((p1, p2) => {
    if (p1.winPctg > p2.winPctg) return -1
    if (p1.winPctg < p2.winPctg) return 1
    return 0
  })

  return result
}
</script>

<style scoped>
table {
  width: 90%;
  margin: 0 auto 30px auto;
}
tbody tr:hover {
  cursor: pointer;
}
</style>
