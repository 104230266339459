<template>
  <div class="squash-league-container">
    <div v-if="loading">
      Loading...
    </div>
    <div v-else-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-else>
      <h1 class="display-4 text-dark">standings</h1>
      <Standings v-bind:matches="matches" />
      <h1 class="display-4 text-dark">match history</h1>
      <MatchHistory v-bind:matches="matches" :perPage="10" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import Standings from './Standings'
import MatchHistory from '@/components/Squash/Common/MatchHistory'
import { getMatches } from '@/services/matches'

export default {
  name: 'SquashLeague',
  components: { Standings, MatchHistory },
  setup() {
    const matches = reactive([])
    const loading = ref(true)
    const errorMessage = ref('')

    getMatches()
      .then((result) => {
        matches.splice(0)
        matches.push(...result)
      })
      .catch((err) => {
        errorMessage.value = err.message
      })
      .finally(() => (loading.value = false))

    return { loading, errorMessage, matches }
  },
}
</script>

<style scoped>
.squash-league-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.squash-league-container div {
  width: 50%;
}

@media (max-width: 1024px) {
  .squash-league-container div {
    width: 100%;
  }
}
</style>
