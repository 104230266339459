<template>
  <section class="title">
    <h1 class="display-1 text-light">manhammer</h1>
    <img src="/assets/hammer.png" height="50" />
  </section>
  <section class="squash bg-light">
    <h1 class="display-2 text-dark">squash</h1>
    <router-view></router-view>
  </section>
  <!-- <section class="squash bg-dark">
    <h1 class="display-1 text-light">running</h1>
  </section> -->
</template>

<script>
export default {
  name: 'Home',
}
</script>
