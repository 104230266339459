import NProgress from 'nprogress'

export const getMatches = () => {
  try {
    NProgress.start()
    const matches = require('../data/matchData.json')
    matches.forEach(sanitize)
    matches.sort((m1, m2) => m1.date.localeCompare(m2.date) * -1)
    return Promise.resolve(matches).finally(() => NProgress.done())
  } catch (err) {
    console.err(err)
    return Promise.reject({ message: 'Oops, something went wrong.' })
  }
}

export const getMatchesForPlayer = (playerName) => {
  return getMatches().then((matches) => {
    return matches.filter((m) => m.p1.name === playerName || m.p2.name === playerName)
  })
}

const sanitize = (match) => {
  match.name = match.p1.name.trim().toLowerCase()
  match.p2.name = match.p2.name.trim().toLowerCase()
}

// creates an artificial delay in the promise chain
// function delay(time) {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(null), time)
//   })
// }
