<template>
  <div class="pagination">
    <button data-testid="prevBtn" @click="decrementPage" :disabled="!hasPreviousPage">&lt;</button>
    Page {{ currentPage }} of {{ numberOfPages }}
    <button data-testid="nextBtn" @click="incrementPage" :disabled="!hasNextPage">&gt;</button>
  </div>
  <slot :results="results" />
</template>

<script>
import { usePagination } from '@/composables/usePagination'
import { watch } from 'vue'

export default {
  props: {
    data: Array,
    perPage: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const { setData, currentPage, numberOfPages, incrementPage, decrementPage, hasPreviousPage, hasNextPage, results } = usePagination(props.data, props.perPage)

    watch(
      () => props.data,
      (data) => setData(data),
    )

    return {
      currentPage,
      numberOfPages,
      incrementPage,
      decrementPage,
      hasPreviousPage,
      hasNextPage,
      results,
    }
  },
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination button {
  margin: 0px 10px;
}
</style>
