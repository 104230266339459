import { ref, watch, watchEffect } from 'vue'

export const usePagination = (initialData, perPage) => {
  const data = ref(initialData)
  const currentPage = ref(1)
  const hasPreviousPage = ref(false)
  const hasNextPage = ref(false)
  const numberOfPages = ref(0)
  const results = ref([])

  watch(data, () => (currentPage.value = 1))

  watchEffect(() => {
    numberOfPages.value = Math.ceil(data.value.length / perPage)
    hasPreviousPage.value = currentPage.value > 1
    hasNextPage.value = currentPage.value < numberOfPages.value

    const start = (currentPage.value - 1) * perPage
    const end = start + perPage
    results.value = data.value.slice(start, end)
  })

  return {
    setData: (a) => (data.value = a),
    currentPage,
    numberOfPages,
    incrementPage: () => (currentPage.value += 1),
    decrementPage: () => (currentPage.value -= 1),
    hasPreviousPage,
    hasNextPage,
    results,
  }
}
