import SquashLeague from '@/components/Squash/SquashLeague'
import Player from '@/components/Squash/Player/Player'

export default [
  {
    path: '/',
    name: 'SquashLeague',
    component: SquashLeague,
  },
  {
    path: '/player/:name',
    name: 'Player',
    component: Player,
    props: (route) => ({ playerName: route.params.name }),
  },
]
