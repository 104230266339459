<template>
  <div class="player-container">
    <div>
      <router-link class="text-dark" :to="{ name: 'SquashLeague' }">&lt; Back to Standings</router-link>
    </div>
    <div v-if="loading">
      Loading...
    </div>
    <div v-else-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-else>
      <h1 class="display-4 text-dark">{{ playerName }} vs. opponent</h1>
      <StatsVsOpponent :matches="matches" :playerName="playerName" />

      <h1 class="display-4 text-dark">match history</h1>
      <MatchHistory :matches="matches" :perPage="10" />

      <!-- <h1 class="display-4 text-dark">win % history</h1>
      <WinChart :matches="matches" :playerName="playerName" /> -->
    </div>
  </div>
</template>

<script>
import { watch, ref } from 'vue'
import { getMatchesForPlayer } from '@/services/matches'
import MatchHistory from '@/components/Squash/Common/MatchHistory'
import StatsVsOpponent from './StatsVsOpponent'
// import WinChart from './WinChart'

export default {
  // WinChart
  components: { StatsVsOpponent, MatchHistory },
  props: {
    playerName: String,
  },
  setup(props) {
    const matches = ref([])
    const loading = ref(true)
    const errorMessage = ref('')
    watch(
      () => props.playerName,
      (name) => {
        loading.value = true
        matches.value.splice(0)
        getMatchesForPlayer(name)
          .then((result) => {
            matches.value.push(...result)
            return matches
          })
          .catch((err) => {
            errorMessage.value = err.message
          })
          .finally(() => (loading.value = false))
      },
      { immediate: true },
    )

    return { loading, errorMessage, matches }
  },
}
</script>

<style scoped>
.player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.player-container div {
  width: 50%;
}

@media (max-width: 1024px) {
  .player-container div {
    width: 100%;
  }
}
</style>
